<template>
  <div class="page">
    <!--<div class="top">-->
    <!--<div class="back"><img src="../../assets/icon_3.png"/></div>-->
    <!--会员中心-->
    <!--</div>-->
    <div class="record" @click="toOrder">
      <span class="spacing">购买记录 ></span>
    </div>
    <div v-for="(item,index) in list" v-bind:key="index">
      <div v-if="item.status === 1" class="list">
        <div class="left">
          <img :src="item.mobileLogo" class="logo" />
        </div>
        <div class="right">
          <div class="name">
            {{item.name}}
            <div class="in" v-on:click="toGoods(item)">进入系统</div>
          </div>

          <div class="content">{{item.blurb}}</div>
        </div>
      </div>
      <div class="jg"></div>
    </div>
  </div>
</template>
<script>
import { list, goodsList } from "@/api/member";

export default {
  name: "index",
  metaInfo: {
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content:
          "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      }
    ]
  },
  data() {
    return {
      list: [],
      goodsListLength: "", //商品个数
      goodsCode:""
    };
  },
  created: function() {
    this.getData();
  },
  methods: {
    //获取应用列表
    getData() {
      let that = this;
      let data = {
        page: 1,
        limit: 1000
      };
      list(data).then(res => {
        that.list = res.rows;
      });
    },
    async getGoodsList(appCode) {
      let that = this;
      let data = { appCode: appCode }; //商品
      await goodsList(data).then(res => {
        that.goodsListLength = res.data.length;
        that.goodsCode = res.data[0].code;
      });
    },
    //跳转会员商品
    async toGoods(item) {
       await this.getGoodsList(item.code);
        if (this.goodsListLength != "1") {
          //判断商品个数
          let data = {
            appCode: item.code
          };
          this.$router.push({ path: "/goodsList", query: data });
        } else {
          let data = {
            appCode: item.code,
            goodsCode: this.goodsCode
          };
          this.$router.push({ path: "/goods", query: data });
        }
    },
    //跳转购买记录
    toOrder() {
      this.$router.push({ path: "/order" });
    }
  }
};
</script>

<style scoped>
.page {
  width: 100%;
}

.top {
  background-color: white;
  color: #222222;
  text-align: center;
  font-size: 24px;
  padding: 10px 20px 10px 20px;
}

.back {
  float: left;
}

.record {
  text-align: right;
  font-size: 12px;
  color: #222222;
  width: 100%;
  background-color: #f8fbfe;
  line-height: 25px;
}

.spacing {
  font-size: 13px;
  padding: 9px 10px 9px 0;
  font-family: SourceHanSansCN-Regular;
}

.logo {
  width: 40px;
  height: 40px;
  margin: 25px 10px 30px 10px;
}

.list {
  width: 100%;
  height: 100px;
}

.left {
  float: left;
  width: 60px;
}

.right {
  /*float: right;*/
  /*width: 77%;*/
  margin-right: 10px;
}

.name {
  font-size: 15px;
  color: #222222;
  padding: 20px 0 7px 0;
  border-bottom: 1px dashed #e6eaef;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: SourceHanSansCN-Bold;
}

.content {
  font-size: 12px;
  color: #888888;
  padding: 7px 0 0px 0;
  margin-bottom: 20px;
  /*overflow: hidden;*/
  /*white-space: nowrap;*/
  /*text-overflow: ellipsis;*/
  font-family: SourceHanSansCN-Regular;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.jg {
  width: 94%;
  height: 5px;
  background-color: #f8fbfe;
  position: relative;
  bottom: 0;
  margin: 0 10px;
}

.in {
  color: #ffffff;
  font-size: 10px;
  background-color: #fb5a10;
  width: 52px;
  border-radius: 10px;
  text-align: center;
  line-height: 17px;
  height: 15px;
  float: right;
  font-family: SourceHanSansCN-Regular;
}
</style>
